.navbar {
  background-color: $black-color;
  height: 60px;

  .navbar-collapse >.navbar-nav {

    >.dropdown.show.nav-item >.dropdown-menu {
      -webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.16);
      background-color: $black-color;
      border: 2px solid $action-color;
      border-radius: 0;
      box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.16);
      padding: 0;

      >.dropdown-item {
        color: $white-color;
        padding: 20px;

        &:hover {
          background-color: $black-color;
        }
      }
    }

    >.nav-item >a {
      color: $white-color;
      padding: 17px;

      &:hover {
        color: $action-color;
      }
    }    
  }

  .navbar-toggler >.navbar-toggler-icon {
    background-image: url('../../svg/hamburguer.svg');
    height: 20px;
    width: 20px;
  }

  .collapse.show.navbar-collapse {
    background-color: $black-color;
    z-index: 9;
  }
}