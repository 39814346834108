.input-group {
  margin-top: 30px;
  position: relative;

  &.-loading {

    input,
    select,
    textarea,
    .css-2b097c-container {
      cursor: pointer;
      width: 100%;

      >.css-yk16xz-control {
        background-color: $gray-color-200;
        border: 1px solid $gray-color-600;
        border-radius: 8px;
        height: 42px;
      }

      >.css-1pahdxg-control {
        background-color: $gray-color-200;
        border: 1px solid $action-color;
        box-shadow: none;
        height: 42px;
      }
    }

    >.loading-wrapper {
      display: block;
    }
  }

  >.loading-wrapper {
    background-color: $gray-color-200;
    display: none;
    margin-bottom: 0;
    margin-left: 5px;
    position: absolute;
    top: 8px;

    >.spinner-border {
      height: 22px;
      width: 22px;
    }
  }

  &.-search {
    transition: ease .4s all;
    width: 70%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &:focus-within {
      width: 100% !important;
    }
  }

  >.input-icon {
    color: $gray-color-600;
    position: absolute;
    right: 32px;
    top: 12px;
  }

  &.-error {
    input,
    select,
    textarea {
      border: 1px solid $error-color;
    }

    >.errormesage {
      display: block !important;
    }
  }

  input,
  select,
  textarea {
    background-color: $white-color;
    border: 1px solid $gray-color-600;
    border-radius: 8px;
    padding: 10px;
    transition: .4s ease all;
    width: 100%;

    &.-search {
      -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
      border: 1px solid $white-color;
      position: relative;

      &:focus {
        -webkit-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);
        box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);
        border: 1px solid $black-color;
        outline: none;
      }

      &:focus ~ .input-icon {
        display: none;
      }
    }
  }

  input,
  select,
  textarea,
  .css-2b097c-container {
    &:focus {
      border: 1px solid $action-color;
      outline: none;
    }

    &:focus ~ .inputname {
      background-color: $action-color;
    }
  }

  >.inputname {
    font-size: 12px;
    margin-bottom: 0;
    padding: 5px;
    position: absolute;
    top: -28px;
    transition: .4s ease all;
  }

  >.errormesage {
    color: $error-color;
    display: block;
    font-size: 12px;
    margin-bottom: 0;
  }
}

// Autocomplete select
.css-2b097c-container {
  cursor: pointer;
  width: 100%;

  >.css-yk16xz-control {
    border: 1px solid $gray-color-600;
    border-radius: 8px;
    height: 42px;
  }

  >.css-1pahdxg-control {
    border: 1px solid $action-color;
    box-shadow: none;
    height: 42px;

    &:hover {
      border: 1px solid $action-color;
      box-shadow: none;
      outline: none;
    }
  }
}
