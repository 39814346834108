.nav-tabs {
  border-bottom: 0;

  >.nav-item >.nav-link {
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
    background-color: transparent;
    border: none;
    color: $gray-color-600;
    cursor: pointer;
    font-size: 18px;

    >.casenumber {
      background-color: $action-color;
      border-radius: 20px;
      color: $black-color;
      font-size: 14px;
      font-weight: bold;
      margin-left: 10px;
      opacity: .3;
      padding: 5px;
    }
  }

  >.nav-item >.active.nav-link {
    border-bottom: 4px solid $action-color;
    color: $black-color;

    >.casenumber {
      opacity: 1;
    }
  }
}