// BOOTSTRAP
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins';

// LAYOUT
@import './Styles/variables';
@import './Styles/structure';
@import './Styles/helpers';
@import './Styles/button';
@import './Styles/card';
@import './Styles/tabs';
@import './Styles/input';
@import './Styles/table';

// COMPONENTS
@import './Components/NavbarComponent/navbarcomponent';
@import './Components/Footer/footer';
@import './Components/Paginator/Paginator';
@import './Components/Lotties/loading';