.button {
  transition: .4s ease all;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;

  &.-primary {
    background-color: $action-color;
    border: 2px solid $black-color;
    color: $black-color;
    padding: 10px 20px;

    &:hover {
      background-color: $black-color;
      color: $action-color;
    }

    &:disabled {
      background-color: $gray-color-200;
      border: 1px solid $gray-color-400;
      color: $gray-color-400;
    }
  }

  &.-secondary {
    background-color: $white-color;
    border: 2px solid $black-color;
    color: $black-color;
    padding: 10px 20px;

    &:hover {
      background-color: $action-color;
      color: $black-color;
    }
  }

  &.-icon {
    background-color: $action-color;
    border: 1px solid $black-color;
    color: $black-color;

    &:hover {
      background-color: $black-color;
      color: $action-color;
    }
  }
}