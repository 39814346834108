.card {
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
  background-color: $white-color;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
  padding: 20px; 
  transition: .4s ease all;
  border: none;

  &.-case {
    .creationdate {
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      text-align: right;
    }

    &:hover{
      -webkit-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);  
      box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.16);
    }
  }
}