.h1-b {
  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
  font-size: 32px;
  font-weight: bold;
  color: $black-color;
}

.h2-r {
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
  font-size: 24px;
  color: $black-color;
}

.h3-b {
  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
  font-size: 18px;
  font-weight: bold;
  color: $black-color;
}

.h3-r {
  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
  font-size: 18px;
  color: $black-color;
}

.body-b {
  font-size: 14px;
  color: $black-color;
  font-weight: bold;
}

.body-r {
  font-size: 14px;
  color: $black-color;
}

.body-l {
  font-size: 14px;
  color: $black-color;
  font-weight: lighter;
}

.body-small {
  font-size: 12px;
  color: $black-color;
}


body {
  background-color: $concrete-color;
}

.loading-users {
  padding: 15px 430px;
  font-size: 25px;
}

.creating-spinner {
  padding: 400px 1000px;
  display: inline;
  position: absolute;
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
  height: 100%;
  width: 100%;
}

.to-right {
  animation: ease-right 1s;
}

.to-left {
  animation: ease-left 1s;
}

@keyframes ease-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(400px);
  }
}

@keyframes ease-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-400px);
  }
}