$black-color: #000000;

$gray-color: #333333;
$gray-color-800: #5B5B5B;
$gray-color-600: #848484;
$gray-color-400: #adadad;
$gray-color-200: #d6d6d6;

$action-color: #ffd800;
$action-color-800: #ffdf33;
$action-color-600: #ffe766;
$action-color-400: #ffef99;
$action-color-200: #fff7cc;

$white-color: #ffffff;
$concrete-color: #f2f2f2;

$error-color: #fc0000;