ul.pagination {
  
  >.page-item a.page-link {
    background-color: transparent;
    border: none;
    color: $black-color;
    font-weight: bold;
  }

  >.page-item.active {
    background-color: $action-color;
    border-radius: 8px;
  }

  >.page-item button.page-link {
    background-color: transparent;
    border: 2px solid $black-color;
    background-color: $white-color;
    border-radius: 8px;
    color: $black-color;
    margin-left: 5px;
    margin-right: 5px;
  }

  >.page-item.disabled button.page-link {
    background-color: transparent;
    border: 2px solid $black-color;
    background-color: $white-color;
    border-radius: 8px;
    color: $black-color;
    margin-left: 5px;
    margin-right: 5px;
    opacity: .4;
  }
}
