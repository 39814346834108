// MARGINS
.mt-xxs {
  margin-top: 5px;
}

.my-xxs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mb-xxs {
  margin-bottom: 5px;
}

.mt-xs {
  margin-top: 10px;
}

.my-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-xs {
  margin-bottom: 10px;
}

.mt-s {
  margin-top: 15px;
}

.my-s {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mb-s {
  margin-bottom: 15px;
}

.mt-m {
  margin-top: 20px;
}

.my-m {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mb-m {
  margin-bottom: 20px;
}

.mt-l {
  margin-top: 30px;
}

.my-l {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mb-l {
  margin-bottom: 30px;
}

.mt-xl {
  margin-top: 40px;
}

.my-xl {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mb-xl {
  margin-bottom: 40px;
}

.mt-xxl {
  margin-top: 50px;
}

.my-xxl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mb-xxl {
  margin-bottom: 50px;
}

.mt-xxxl {
  margin-top: 100px;
}

.my-xxxl {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mb-xxxl {
  margin-bottom: 100px;
}
// MARGINS

.min-h {
  min-height: calc(100vh - 236px);
}