table,
thead,
tbody,
tr,
th,
td {
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

table >thead {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  background-color: $black-color;

  th {
    color: $white-color;
    padding: 10px 5px;
  }
}

table >tbody >tr >td {
  @include media-breakpoint-down(sm) {
    border: 0 !important;
    padding: 5px !important;
  }
}

table >tbody >tr {
  @include media-breakpoint-down(sm) {    
    padding: 10px 0;    
  }
  border-bottom: 1px solid $gray-color-200 !important;

  &:last-child {
    border-bottom: none !important;
  }

  >td {
    padding: 20px 5px;

    >button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

table {
  font-size: 14px;
  width: 100%;
}